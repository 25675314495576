.landing-cover {
  color: white;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 100px;
  padding-bottom: 80px;
  background: url("../Assets/landing_cover.jpg");
  /* background: url('../Assets/landing_cover.jpg') rgba(0, 0, 0, .5);
    background-blend-mode: multiply; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
}

.cover-text {
  width: 600px;
  padding: 40px 60px 60px 60px;
  /* background-color: rgba(159, 45, 34, 0.7); */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: justify;
}

.cover-text-font {
  font-size: 18px;
}

.cover-title-text {
  font-size: 30px;
  font-weight: bold !important;
}

.navi-picture-row {
  margin: 60px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: center;
  flex-wrap: wrap;
}

.picture-width {
  width: 400px;
  text-align: center;
}

.picture-width :hover {
  /* background-color: var(--app-light-gray); */
  filter: drop-shadow(5px 5px 5px rgba(34, 56, 112, 0.5));
}

.navi-picture {
  width: 400px;
  height: 200px;
  object-fit: cover;
}

.navi-text {
  color: var(--app-primary);
  font-size: 20px !important;
  margin-top: 20px !important;
}

.content-title {
  margin: 30px 0px !important;
  font-size: 30px !important;
  font-weight: bold !important;
}

@media only screen and (max-width: 479px) {
  .landing-cover {
    height: 240px;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .cover-text {
    width: 290px;
    height: 180px;
    padding: 20px 30px 30px 30px;
    text-align: left;
  }

  .cover-text-font {
    font-size: 10px !important;
  }

  .cover-title-text {
    font-size: 14px !important;
  }

  .navi-picture {
    width: 300px;
  }

  .navi-text {
    font-size: 18px !important;
  }
  .navi-picture-row {
    margin: 15px 0px;
  }

  .content-title {
    margin: 20px 0px !important;
    font-size: 25px !important;
  }
}
