.square-button {
  width: 150px;
  height: 80px;
  font-weight: bold;
  font-size: 15px;
}

.button-box {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 60px;
  align-items: center;
  justify-content: center;
}

.resource-title {
  margin-bottom: 25px !important;
}

@media only screen and (max-width: 479px) {
  .square-button {
    width: 300px;
    height: 50px;
    margin-top: 20px !important;
    margin-left: 20px !important;
  }
  .button-box {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .resource-title {
    font-size: 25px !important;
  }
}
