.footer-section {
  padding: 40px 60px;
  /* background-color: #333333; */
  background-color: var(--app-primary);
  margin-top: 40px;
  color: white;
}

.foot-content {
  display: flex;
  flex-direction: row;
}

.bac-logo {
  width: 350px;
  object-fit: contain;
}

.page-links {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  justify-content: space-around;
  margin: auto;
}

.foot-text-container {
  width: 350px;
}

.foot-text {
  font-size: 13px !important;
}

.external-logo {
  width: 50px;
  object-fit: contain;
}

.fhwa-logo {
  width: 300px;
  height: 55px;
  object-fit: contain;
}

@media only screen and (max-width: 479px) {
  .fhwa-logo {
    width: 300px;
  }

  .bac-logo {
    width: 300px;
  }

  .foot-text-container {
    width: 300px;
  }

  .foot-text {
    font-size: 10px !important;
  }

  .footer-section {
    padding: 20px 30px;
  }

  .foot-content {
    display: inline;
  }

  .external-logo {
    width: 40px;
  }
}
