.header-bar {
  height: 90px;
  /* background-color: #F5F5F5; */
  background-color: var(--app-primary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  color: white;
  font-weight: bold;
  font-size: 27px;
  text-align: center;
}
.notification {
  height: 45px;
  background-color: var(--app-info);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  color: #715011;
  font-weight: bold;
  text-align: center;
}

.logo {
  height: 30px;
}
.logo:hover {
  filter: drop-shadow(5px 5px 5px rgba(255, 255, 255, 0.2));
}

.title-section {
  background-color: var(--app-secondary);
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: max-content;
  padding: 10px 80px 10px 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: 479px) {
  .header-bar {
    height: 50px;
    margin: 0;
    padding: 0;
  }

  .logo {
    margin: 16px;
    height: 20px;
    width: 130px;
  }

  .title-section {
    width: 100px;
    font-size: 12px;
    padding: 10px 20px 10px 20px;
  }

  .left-icon {
    font-size: 10px !important;
  }
}
