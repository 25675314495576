.login-panel {
  width: 700px;
  padding: 60px 0px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  align-self: center;
  background-color: #f5f5f5;
  color: var(--app-text);
}

.login-fields {
  margin: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.horizontal {
  border: none;
  border-top: 3px solid rgb(176, 173, 173);
  width: 600px;
  /* margin-top: 40px;
    margin-bottom: 20px; */
}

.other-options {
  width: 400px;
  justify-content: space-between;
}

.vertical {
  border: none;
  border-right: 2px solid rgb(176, 173, 173);
  height: 20px;
}

@media only screen and (max-width: 479px) {
  .login-panel {
    width: 360px;
    height: 600px;
  }

  .login-fields {
    width: 300px;
  }

  .horizontal {
    width: 300px;
  }

  .other-options {
    width: 300px;
  }
}
